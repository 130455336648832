export class MockConfig {
  constructor() {
    /*BotanicFamilyApi: boolean = false;
    CultivationPeriodApi: boolean = false;
    CultivationApi: boolean = false;
    GrowingStepApi: boolean = false;
    PlantApi: boolean = false;
    taskApi: boolean = false;*/
    this.dms = false;
    this.tasks = false;
    this['growing-steps'] = false;
    this.social = false;
    this.user = false;
    this.weather = false;
    this.plants = false;
    this['cultivation-periods'] = false;
    this['plants-to-growing-steps'] = false;
    this['user-suggestions'] = false;
    this['botanic-families'] = false;
    this['notifications'] = false;
    this.users = false;
    this.cultivations = false;
    this.locations = false;
    this.risks = false;
  }
}