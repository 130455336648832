import { MockService } from '../mock/mock.service';
export class SdkConfigService {
  constructor() {
    this.mockService = MockService.getInstance();
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new SdkConfigService();
    }
    return this.instance;
  }
  static configure(config) {
    this.getInstance().setConfig(config);
    return this.getInstance();
  }
  setMock(isMockEnabled, mockConfig) {
    this.mockService.isMockEnabled = isMockEnabled;
    if (mockConfig) {
      this.mockService.mockConfig = mockConfig;
    }
  }
  setConfig(config) {
    this.config = config;
    this.mockService.mockConfig = config.mockConfig;
  }
  getConfig() {
    return this.config;
  }
}