var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { FlutterStorageHelper } from "./flutter-storage.helper";
export class StorageHelper {
  static getInstance() {
    if (!this.instance) {
      this.instance = new StorageHelper();
    }
    return this.instance;
  }
  constructor() {
    this.flutterStorage = FlutterStorageHelper.getInstance();
  }
  getItem(key) {
    return localStorage.getItem(key);
  }
  getMobileStorageItem(key) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.flutterStorage.getValue(key);
    });
  }
  getSessionItem(key) {
    return sessionStorage.getItem(key);
  }
  setItem(key, value) {
    localStorage.setItem(key, value);
  }
  setMobileStorageItem(key, value) {
    this.flutterStorage.writeValue(key, value);
  }
  setSessionItem(key, value) {
    sessionStorage.setItem(key, value);
  }
  removeItem(key) {
    localStorage.removeItem(key);
  }
  removeSessionItem(key) {
    sessionStorage.removeItem(key);
  }
}