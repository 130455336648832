import { MockConfig } from '../config/mock-config';
export class MockService {
  constructor() {
    this.isMockEnabled = false;
    this.mockConfig = new MockConfig();
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new MockService();
    }
    return this.instance;
  }
  isMockEnabledByService(serviceName) {
    if (!this.isMockEnabled) {
      return false;
    }
    if (this.mockConfig[serviceName] !== undefined) {
      return this.mockConfig[serviceName];
    }
    return this.isMockEnabled;
  }
}